import {
  doFetchUploadSignatureAndPolicy,
  doS3Upload,
} from "@/services/image.service";
import { authHeader } from "./auth";
import { meWrapRequest, wrapRequest } from "./nav";

const vueAppMagenotApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;
const vueAppS3Url = import.meta.env.VITE_APP_S3_URL;
const assetNodeslsNewUrl = import.meta.env.VITE_ASSETS_NODESLS_NEW_URL;

export const doValidateAddress = wrapRequest((address) => {
  const requestOptions = {
    method: "GET",
  };
  return fetch(
    "https://maps.googleapis.com/maps/api/geocode/json?address=" +
      address +
      "&key=AIzaSyDmHp0PPuKHD1KGHCDmLq1qQKr_03XKEHo",
    requestOptions
  );
});
export const doFetchStates = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/directory/countries/US`,
    requestOptions
  );
});
export const doFetchCountries = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };

  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/directory/countries`,
    requestOptions
  );
});
export const doFetchBasicInfo = meWrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions);
});

export const doFetchChangePassword = async (currentPassword, newPassword) => {
  /* eslint-disable no-alert, no-console ,  no-debugger */
  console.log("currentPassword: ", currentPassword);
  console.log("newPassword : ", newPassword);

  return false;
};
export const doUpdateAddress = meWrapRequest((one, address) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      customer: {
        firstname: one.firstname,
        lastname: one.lastname,
        email: one.email,
        website_id: one.websiteId,
        addresses: [
          {
            region_id: address.regionId,
            country_id: address.countryId,
            street: [address.streetNoOne, address.streetNoTwo],
            postcode: address.postcode,
            city: address.city,
            telephone: address.telephone,
            firstname: address.firstname,
            lastname: address.lastname,
          },
        ],
        default_shipping: one.defaultShipping,
        default_billing: one.defaultBilling,
      },
    }),
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions);
});
export const doUpdateUserAddress = meWrapRequest((one, addresses) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      customer: {
        firstname: one.firstname,
        lastname: one.lastname,
        email: one.email,
        website_id: one.websiteId,
        addresses: addresses,
        default_shipping: one.defaultShipping,
        default_billing: one.defaultBilling,
      },
    }),
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions);
});
export const doUpdateSubscription = meWrapRequest((one) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      customer: {
        firstname: one.firstname,
        lastname: one.lastname,
        email: one.email,
        website_id: one.websiteId,
        extension_attributes: {
          is_subscribed: one.extensionAttributes.isSubscribed,
        },
      },
    }),
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions);
});
export const doChangeBasicInfo = meWrapRequest((one) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      customer: {
        firstname: one.firstname,
        lastname: one.lastname,
        email: one.email,
        website_id: one.websiteId,
      },
    }),
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions);
});
export const doChangePassword = meWrapRequest(
  (currentPassword, newPassword) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        ...authHeader(),
      },
      body: JSON.stringify({
        currentPassword: currentPassword,
        newPassword: newPassword,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/customers/me/password`,
      requestOptions
    );
  }
);
export const doFetchEstimatedShippingMethods = wrapRequest((one, address) => {
  let streetAddress = [];
  let streetArray = [];
  if (address.streetNoTwo == " " || address.streetNoTwo == undefined) {
    streetAddress[0] = address.streetNoOne;
    streetArray = streetAddress.filter((v) => v != "");
  } else {
    streetAddress[0] = address.streetNoOne;
    streetAddress[1] = address.streetNoTwo;
    streetArray = streetAddress.filter((v) => v != "");
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      /*"address": {
                 "region_id": regionId,
                 "country_id": countryId
             }*/
      address: {
        region: address.region.region,
        region_id: address.regionId,
        region_code: address.regionCode,
        country_id: address.countryId,
        street: streetArray,
        postcode: address.postcode,
        city: address.city,
        firstname: address.firstname,
        lastname: address.lastname,
        customer_id: one.id,
        email: one.email,
        telephone: address.telephone,
        same_as_billing: 1,
      },
    }),
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/estimate-shipping-methods`,
    requestOptions
  );
});
export const doShippingInformation = wrapRequest((one, address, estimated) => {
  let streetAddress = [];
  let streetArray = [];
  if (address.streetNoTwo == " " || address.streetNoTwo == undefined) {
    streetAddress[0] = address.streetNoOne;
    streetArray = streetAddress.filter((v) => v != "");
  } else {
    streetAddress[0] = address.streetNoOne;
    streetAddress[1] = address.streetNoTwo;
    streetArray = streetAddress.filter((v) => v != "");
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      ...authHeader(),
    },
    body: JSON.stringify({
      /*"address": {
                 "region_id": regionId,
                 "country_id": countryId
             }*/
      addressInformation: {
        shipping_address: {
          region: address.region.region,
          region_id: address.regionId,
          region_code: address.regionCode,
          country_id: address.countryId,
          street: streetArray,
          postcode: address.postcode,
          city: address.city,
          firstname: address.firstname,
          lastname: address.lastname,
          customer_id: one.id,
          email: one.email,
          telephone: address.telephone,
        },
        shipping_carrier_code: estimated.carrierCode,
        shipping_method_code: estimated.methodCode,
      },
    }),
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/shipping-information`,
    requestOptions
  );
});
export const doDeleteAddress = wrapRequest((addressId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/addresses/${addressId}`,
    requestOptions
  );
});

export const doProductsOrder = meWrapRequest(
  (userQuote, paymentMethod, oneAddress) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartId: userQuote,
        billingAddress: oneAddress,
        paymentMethod: paymentMethod,
        comments: "",
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/payment-information`,
      requestOptions
    );
  }
);

export const doFetchS3Svg = async (fileUrl) => {
  return fetch(`${vueAppS3Url}/${fileUrl}`)
    .then(async (response) => {
      if (!response.ok) {
        return null;
      }
      return await response.text();
    })
    .catch(async (error) => {
      // let errorText = await error.text();
      // errorText = JSON.parse(errorText);
      throw error;
    });
};

export const uploadContentToS3 = async (payload) => {
  try {
    let signature = await doFetchUploadSignatureAndPolicy(
      payload.bucketName,
      payload.bucketRegion,
      payload.name,
      payload.type
    );
    if (signature) {
      let image = await doS3Upload(payload.data, signature.url, payload.type);
      return image;
    }
  } catch (err) {
    throw err;
  }
};


export const doFetchProductS3Summary = meWrapRequest((fileUrl) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(`${fileUrl}`, requestOptions);
});
