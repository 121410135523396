import { wrapRequest, wrapEmptyRequest } from './nav';
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

export const doPostOne = wrapRequest(({specs}) => {
    
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify({ specs })
    };
    return fetch(`${imageLibApiUrl}/rpc/builder_session_create`, requestOptions)
})

export const doPatchOne = wrapRequest(({ sessionId, property }) => {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify({ ...property })
    };
    return fetch(`${imageLibApiUrl}/builder_session?id=eq.${sessionId}`, requestOptions)
})

export const doFetchOne = wrapRequest(({ sessionId }) => {
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
        }
    };
    return fetch(`${imageLibApiUrl}/builder_session?id=eq.${sessionId}`, requestOptions)
})

export const doFetchSessionListByOrderState = wrapRequest(({orderState}) => {
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        }
    };
    return fetch(`${imageLibApiUrl}/builder_session?order_state=ilike.*${orderState}*&order=start_ts.desc`, requestOptions)
})


export const doFetchList = wrapRequest(({ sku, guestId, userId }) => {
    
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json'
        }
    };

    const params = {}
    if (sku) params['product_data->>sku'] = `eq.${sku}`
    if (guestId) params['user_data->>guest_id'] = `eq.${guestId}`
    if (userId) params['user_data->>customer_id'] = `eq.${userId}`
    
    const qs = Object.keys(params).map(key => key + '=' + params[key]).join('&');

    return fetch(`${imageLibApiUrl}/builder_session?${qs}&state=not.eq.in_active&select=*,builder_session_item(builder_session_id,thumbnail_url)`, requestOptions)
    // &select=thumbnail_url,builder_session_item(builder_session_id)
})
export const doDeleteSession = wrapEmptyRequest((sessionId) => {
    // eslint-disable-next-line no-debugger
    // debugger
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json', 
        }
    };
    return fetch(`${imageLibApiUrl}/builder_session?id=eq.${sessionId}`, requestOptions)

});

