const vueAppMagenotApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;
const vueAppMaskId =import.meta.env.VITE_APP_MASK_ID;

import { authHeader } from './auth';
import { meWrapRequest } from './nav';
export const registrationService = {
    doResetPassword,
    doConfirmEmail,
    doSignUp,
    doForgotPassword
};
//function handleResponse(response, retryFunction){
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        } else {
            return data;
        }
    });
}

function doResetPassword(resetOne) { //userId, newPassword, token
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "email": resetOne.email, "resetToken": resetOne.token, "newPassword": resetOne.newPassword })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/resetPassword`, requestOptions).then(handleResponse);
}

function doForgotPassword(forgot) {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            "email": forgot.email,
            "template": "email_reset",
            "websiteId": 1
        })
    };

    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/password`, requestOptions).then(handleResponse);
}
//async function doconfirmEmail(token) {
async function doConfirmEmail() {
    let details = {
        "massages": 'Your Email id confirm Please login In',
        "response": "400"
    }
    return details;
}

function doSignUp(registration) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        },
        body: JSON.stringify({
            "customer": {
                "email": registration.email,
                "firstname": registration.firstname,
                "lastname": registration.lastname
            },
            "password": registration.password,
            "mask_id":vueAppMaskId
            
        })

    };

    // console.log(registration);

    return fetch(`${vueAppMagenotApiUrl}/rest/all/V1/register-customers`, requestOptions).then(handleResponse);
}
/* eslint-disable no-alert, no-console ,  no-debugger */
export const doUpdateSubscription = meWrapRequest((one) => {
    const requestOptions = {
        method: 'PUT',
        headers: {
            'Content-type': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            "customer": {
                "firstname": one.firstname,
                "lastname": one.lastname,
                "email": one.email,
                "website_id": one.website_id,
                "extension_attributes": {
                    "is_subscribed": true
                }
            }
        })
    };
    return fetch(`${vueAppMagenotApiUrl}/rest/V1/customers/me`, requestOptions)
});