/* eslint-disable no-debugger */
import { wrapRequest, wrapEmptyRequest } from "./nav";
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

export const doPostOne = wrapRequest(({ item }) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json",
      Prefer: "return=representation",
    },
    body: JSON.stringify({ ...item }),
  };
  return fetch(`${imageLibApiUrl}/builder_session_item`, requestOptions);
});

export const doPatchOne = wrapRequest(({ sessionItemId, property }) => {
  const requestOptions = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json",
      Prefer: "return=representation",
    },
    body: JSON.stringify({ ...property }),
  };
  return fetch(
    `${imageLibApiUrl}/builder_session_item?id=eq.${sessionItemId}`,
    requestOptions
  );
});

export const doPatchItemProperties = wrapRequest(
  ({ sessionId, productPartId, surfaceType, surfaceId, property }) => {
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/vnd.pgrst.object+json",
        Prefer: "return=representation",
      },
      body: JSON.stringify({ ...property }),
    };

    return fetch(
      `${imageLibApiUrl}/builder_session_item?builder_session_id=eq.${sessionId}&product_part_id=eq.${productPartId}&surface_type=eq.${surfaceType}&surface=eq.${surfaceId}`,
      requestOptions
    );
  }
);

export const doFetchOne = wrapRequest(
  ({ itemId, sessionId, surface, surfaceType }) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-type": "application/json",
        // 'Accept': 'application/vnd.pgrst.object+json',
      },
    };

    let params = {};
    if (itemId) params["product_part_id"] = `eq.${itemId}`;
    if (sessionId) params["builder_session_id"] = `eq.${sessionId}`;
    if (surface) params["surface"] = `eq.${surface}`;
    if (surfaceType) params["surface_type"] = `eq.${surfaceType}`;

    const qs = Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&");

    return fetch(
      `${imageLibApiUrl}/builder_session_item?${qs}`,
      requestOptions
    );
  }
);

export const doFetchList = wrapRequest(({ sessionId }) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
    },
  };

  let params = {};
  if (sessionId) params["builder_session_id"] = `eq.${sessionId}`;

  const qs = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  return fetch(
    `${imageLibApiUrl}/builder_session_item?${qs}&order=created_at.asc`,
    requestOptions
  );
});

export const doDeleteSessionItem = wrapEmptyRequest((sessionId) => {
  // debugger
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
    },
  };
  return fetch(
    `${imageLibApiUrl}/builder_session_item?builder_session_id=eq.${sessionId}`,
    requestOptions
  );
});
