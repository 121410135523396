import { wrapRequest } from './nav';
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;
const assetNodeslsUrl = import.meta.env.VITE_APP_ASSETS_NODESLS_URL;
const vueAppMagentoApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;

export const doFetchFonts = wrapRequest(() => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    return fetch(`${imageLibApiUrl}/font?limit=10`, requestOptions);
});

export const doFetchLiners = wrapRequest((tags) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    let res = fetch(`${imageLibApiUrl}/liner?tags=ov.{${tags}}`, requestOptions);
    return res;
});
export const doFetchColors = wrapRequest((tags) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    let res = fetch(`${imageLibApiUrl}/color?tags=ov.{${tags}}&order=sort.asc`, requestOptions);
    return res;
});

export const doFetchPaperByIds = wrapRequest((paperIds) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    let res = fetch(`${imageLibApiUrl}/paper?id=in.(${paperIds})`, requestOptions);
    return res;
});

export const doFetchMonogramByIds = wrapRequest((monogramIds) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    let res = fetch(`${imageLibApiUrl}/monogram?id=in.(${monogramIds})`, requestOptions);
    return res;
});

export const doFetchFontByIds = wrapRequest((fontIds) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    let res = fetch(`${imageLibApiUrl}/font?id=in.(${fontIds})`, requestOptions);
    return res;
});

export const doFetchProductDetails = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer xi7a8g1ey22veysbp6ckan4yjqqkgjys'
        },
    };
    return fetch(`${vueAppMagentoApiUrl}/rest/V2/product/${sku}`, requestOptions)
});
export const doFetchProductCategory= wrapRequest((catId) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer xi7a8g1ey22veysbp6ckan4yjqqkgjys'
        },
    };
    return fetch(`${vueAppMagentoApiUrl}/rest/all/V1/crcategories/${catId}`, requestOptions)
});
export const doFetchProductsAttributes = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
            'Authorization': 'Bearer 58rcjzlop08412gs0xa42q3qna21zxo2',
            'Access-Control-Allow-Origin': '*',
        },
    };
    return fetch(`${vueAppMagentoApiUrl}/rest/all/V1/crproducts/${sku}?fields=sku,custom_attributes`, requestOptions)
});
export const doFetchCatelogProducts = wrapRequest((searchCriteria) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' }
    };

    return fetch(`${vueAppMagentoApiUrl}/rest/V2/suggestion?q=${searchCriteria}`, requestOptions);
});
export const doFetchProductThumbnail = wrapRequest((cartId, itemId) => {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${assetNodeslsUrl}/cart-item-thumbnails?itemId=${itemId}&cartId=${cartId}&preview=true`, requestOptions);
});
export const doFetchProductTags = wrapRequest((sku) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json', },
    };
    return fetch(`${imageLibApiUrl}/product?sku=eq.${sku}&select=tags,sku`, requestOptions);
})
export const doFetchSessionProductDetails = wrapRequest(({sku}) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    return fetch(`${vueAppMagentoApiUrl}/rest/V1/crproducts/?searchCriteria[filter_groups][0][filters][0][field]=sku&searchCriteria[filter_groups][0][filters][0][value]=${sku}&searchCriteria[filter_groups][0][filters][0][condition_type]=equal`, requestOptions);
})

export const doLookupPricerDataset = wrapRequest(({ papers, uses, occassions }) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ occasions: occassions || [], papers: papers || [], uses: uses || [] })
    };
    return fetch(`${imageLibApiUrl}/rpc/lookup_pricer_dataset`, requestOptions);
})