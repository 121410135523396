//import { authAdminHeader } from './auth';
import { wrapRequest } from './nav';
import { adminAuthHeader } from './auth';
const vueAppMagentoApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;
const adminLoginUrl = import.meta.env.VITE_APP_ADMIN_LOGIN_URL ? import.meta.env.VITE_APP_ADMIN_LOGIN_URL : '/gate/sign-in';

const handleError = async errorResponse => {
    if (!errorResponse.ok) {
        switch (errorResponse.status) {
            case 401:
                {
                    sessionStorage.studio_interrupted_page = window.location.href;
                    sessionStorage.studio_interrupted_message = 'There was an access violation caused by your request. You need to sign back in please.';
                    window.location.href = adminLoginUrl;
                    break;
                }

            default:
                throw errorResponse;

        }
    }
};

export const isAdmin = () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-type': 'application/json', ...adminAuthHeader()
        },
    };
    return fetch(`${vueAppMagentoApiUrl}/rest/V2/is-admin`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw response;
            }
            return response.json();
        })
        .catch(error => handleError(error));
};



export const doAdminLogin = wrapRequest((loginDetail) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({
            username: loginDetail.email,
            password: loginDetail.password
        })
    };
    return fetch(`${vueAppMagentoApiUrl}/rest/all/V1/integration/admin/token`, requestOptions);
});

