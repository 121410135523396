import { wrapRequest } from "./nav";

const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

export const doFetchList = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/font?limit=100`, requestOptions);
  //return fetch(`${fontsLibApiUrl}/font?name=ilike.*${search}*`, requestOptions);
});

export const doFetchOne = wrapRequest((id) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/product?id=eq.${id}`, requestOptions);
});

export const doFetchProductOneBySku = wrapRequest((sku) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/product?sku=eq.${sku}`, requestOptions);
});

export const doFetchFonts = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/font?limit=10`, requestOptions);
});

export const doLookupFonts = wrapRequest((search) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };

  return fetch(
    `${imageLibApiUrl}/font?name=ilike.*${search}*&limit=10`,
    requestOptions
  );
});
export const doFetchPaper = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/paper`, requestOptions);
});
export const doLookupPaper = wrapRequest((search) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };

  return fetch(
    `${imageLibApiUrl}/paper?name=ilike.*${search}*&limit=10`,
    requestOptions
  );
});

export const doFetchMotifs = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/motif?limit=10`, requestOptions);
});
export const doLookupMotifs = wrapRequest((search) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };

  return fetch(
    `${imageLibApiUrl}/motif?name=ilike.*${search}*&limit=10`,
    requestOptions
  );
});
export const doFetchMonograms = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(
    `${imageLibApiUrl}/monogram?limit=10&tags=not.ov.{missing-woff}`,
    requestOptions
  );
});
export const doLookupMonograms = wrapRequest((search) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };

  return fetch(
    `${imageLibApiUrl}/monogram?name=ilike.*${search}*&limit=10&tags=not.ov.{missing-woff}`,
    requestOptions
  );
});
export const doFetchLiners = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/liner`, requestOptions);
});
export const doFetchColors = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(`${imageLibApiUrl}/color?order=sort.asc`, requestOptions);
});
export const doFetchImages = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };
  return fetch(
    `${imageLibApiUrl}/image?select=id,tags&tags=neq.{}`,
    requestOptions
  );
});

export const doFetchCatelogProducts = wrapRequest((searchCriteria) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-type": "application/json" },
  };

  return fetch(
    `https://www.crane.com/search/ajax/suggest/?q=${searchCriteria
      .split(" ")
      .join("+")}`,
    requestOptions
  );
});

export const doPostCloneProduct = wrapRequest((product) => {
  if (!product.s3_id) {
    let s3Id = product.s_3_id;
    delete product.s_3_id;
    product.s3_id = s3Id;
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json",
      Prefer: "return=representation",
    },
    body: JSON.stringify(product),
  };

  return fetch(`${imageLibApiUrl}/product`, requestOptions);
});

export const doPostCloneProductParts = wrapRequest((part) => {
  if (!part.s3_id) {
    let s3Id = part.s_3_id;
    delete part.s_3_id;
    part.s3_id = s3Id;
  }
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/vnd.pgrst.object+json",
      Prefer: "return=representation",
    },
    body: JSON.stringify(part),
  };

  return fetch(`${imageLibApiUrl}/product_part`, requestOptions);
});
