// import { authHeader } from './auth';
import { wrapRequest } from './nav';
const imageLibApiUrl = import.meta.env.VITE_IMAGELIB_API_URL;

// eslint-disable-next-line no-unused-vars
export const doFetchList = wrapRequest((id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    return fetch(`${imageLibApiUrl}/product_part?product_id=eq.${id}&order=order_count`, requestOptions);
});
export const doFetchOne = wrapRequest((id) => {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-type': 'application/json' },
    };
    return fetch(`${imageLibApiUrl}/product_part?id=eq.${id}`, requestOptions);
});

export const dolookUpList = wrapRequest((search) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify({ name: search.name || '', brand: search.brand || '', organization_id: search.organizationId, tags: search.tags, _limit: 100 })
    };
    return fetch(`${imageLibApiUrl}/rpc/lookup_product_part`, requestOptions);
})


export const doPostOne = wrapRequest((product) => {
    if (!product.s3_id) {
        let s3Id = product.s_3_id;
        delete product.s_3_id;
        product.s3_id = s3Id;
    }
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify(product)
    };

    return fetch(`${imageLibApiUrl}/product_part`, requestOptions)

})

export const doPatchOne = wrapRequest((product) => {
    if (!product.s3_id) {
        let s3Id = product.s_3_id;
        delete product.s_3_id;
        product.s3_id = s3Id;
    }
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json', 'Accept': 'application/vnd.pgrst.object+json',
            'Prefer': 'return=representation'
        },
        body: JSON.stringify(product)
    };
    return fetch(`${imageLibApiUrl}/product_part?id=eq.${product.id}`, requestOptions)
})
export const doPatchList = wrapRequest((list) => {
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json'            
        },
        body: JSON.stringify(list)
    };
    return fetch(`${imageLibApiUrl}/product_part`, requestOptions);
})

export const doDeleteSelected = wrapRequest((productIds) => {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Prefer': 'return=representation'
        },
    };

    return fetch(`${imageLibApiUrl}/product_part?id=in.(${productIds})`, requestOptions)

})
