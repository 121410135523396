import { wrapRequest } from './nav';
const vueAppCraneOrder = import.meta.env.VITE_CRANE_ORDER_API_URL;
export const doFetchPartPrice = wrapRequest((part) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
            'Access-Control-Allow-Origin': '*',       
        },
        body: JSON.stringify(part)
    };
    return fetch(`${vueAppCraneOrder}/pricer/price`, requestOptions)
});

