import { getCookieByName } from "@/services/auth";
/* eslint-disable no-alert, no-console ,   */

export const getSVGParts = (options) => {
  let partOptions = [];
  options.forEach((option) => {
    partOptions.push({
      text: option.name,
      callback: () => option.id,
      value: option,
    });
  });
  return partOptions;
};

export const getAlignmentOptions = (options) => {
  let alignmentOptions = [];
  options.forEach((option) => {
    alignmentOptions.push({
      text: option,
      callback: () => option,
    });
  });
  return alignmentOptions;
};
export const getFontSizeOptions = ({ fontMin, fontMax }) => {
  let fontSizeOptions = [];

  for (let i = fontMin; i <= fontMax; i++) {
    fontSizeOptions.push({
      text: i + "pt",
      callback: () => i + "pt",
      value: i,
    });
  }

  return fontSizeOptions;
};

export const getPrintOptions = (options) => {
  let printOptions = [];

  options.forEach((option) => {
    printOptions.push({
      text: option,
      callback: () => option,
    });
  });
  return printOptions;
};
export const getPaperOptions = (options) => {
  let paperOptions = [];

  options.forEach((option) => {
    paperOptions.push({
      text: option.name,
      weight: option.weight,
      texture: option.texture,
      img: option.url,
      description: option.description,
      brand: option.brand,
      color: option.color,
      colorHex: option.colorHex,
    });
  });
  return paperOptions;
};
export const getPaperSurfaceOptions = (paperObject) => {
  let paperSurfaces = [];
  let surface = null;
  for (let s = 0; s < surfaceIds.length; s++) {
    if (paperObject.alternateSurfaceId == surfaceIds[s].id) {
      paperSurfaces.push({
        name: surfaceIds[s].name,
        id: surfaceIds[s].id,
        url: paperObject.alternateUrl,
      });
    }
    if (paperObject.surfaceId == surfaceIds[s].id) {
      surface = {
        name: surfaceIds[s].name,
        id: surfaceIds[s].id,
        url: paperObject.url,
      };
      paperSurfaces.push(surface);
    }
  }
  return { surfaceOptions: paperSurfaces, surface };
};

export const commonValidationRules = {
  requiredRules: [(v) => !!v || "This field is required"],
  minLengthTenChars: [
    (v) => !!v || "This field is required",
    (v) => (v && v.length >= 10) || "This field must be at least 10 characters",
  ],
  emailIdRules: [
    (v) => !!v || "E-mail is required",
    (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
  ],
  passwordRule: [
    (v) => !!v || "Password is required",
    (v) =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
        v
      ) ||
      "Password must contain atleast one lowercase, one uppercase, one digit and one special character",
  ],
};
export const getCurrentDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};
export const isoStringToYMD = (date) => {
  let newDate = new Date(date);
  let year = newDate.getFullYear();
  let month = newDate.getMonth() + 1;
  let dt = newDate.getDate();
  let hr = newDate.getHours();
  let minutes = newDate.getMinutes();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hr < 10) {
    hr = "0" + hr;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  return year + "-" + month + "-" + dt + " " + hr + ":" + minutes;
};
export const getCurrentYMDDate = () => {
  var d = new Date(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("/");
};
export const isoStringToNormalDate = function () {
  // let tmp = new Date().toLocaleDateString().split("/");
  //return tmp[2] + "-" + tmp[1] + "-" + tmp[0];
  let tmp = new Date().toISOString().split("T")[0];
  tmp = tmp.split("-");
  return tmp[0] + "-" + tmp[1] + "-" + tmp[2];
};

export const doGetImageBuffer = async (url) => {
  return fetch(url).then((res) => res.buffer());
};

export const processDetails = [
  { name: "Thermography", processID: 1, shortName: "T" },
  { name: "Engraving", processID: 2, shortName: "E" },
  { name: "Letterpress", processID: 5, shortName: "L" },
  { name: "Digital", processID: 21, shortName: "G" },
  { name: "Embossing", processID: 6, shortName: "B" },
  { name: "Flat", processID: 7, shortName: "F" },
  { name: "Flat Print", processID: 7, shortName: "F" },
  { name: "Foil", processID: 15, shortName: "R" },
];

export const processors = {
  Thermography: 1,
  Engraving: 2,
  Letterpress: 5,
  Digital: 21,
  Embossing: 6,
  "Flat Print": 7,
  Flat: 7,
  Foil: 15,
};

// export const SublineTypeIds = {
//     "text": 11,
//     "motif": 9,
//     "liner": 8
// }

export const SublineTypeIds = {
  text: 14,
  motif: 14,
  monogram: 14,
  liner: 8,
  image: 14,
  clipart: 14,
};
export const surfaceIds = [
  { name: "Front", id: 1 },
  { name: "Back", id: 2 },
  { name: "Face", id: 3 },
  { name: "Flap", id: 4 },
  { name: "Inside", id: 5 },
  { name: "Outside", id: 6 },
  { name: "Bottom Back", id: 7 },
];
export const colorsName = [
  { name: "Newport Blue", cid: 31, colorHex: "", colorShort: "NBL" },
  { name: "Regent Blue", cid: 38, colorHex: "", colorShort: "RBL" },
  { name: "Navy", cid: 30, colorHex: "", colorShort: "NAV" },
  { name: "Peacock", cid: 184, colorHex: "", colorShort: "PCK" },
  { name: "French Blue", cid: 20, colorHex: "", colorShort: "FBL" },
  { name: "Moss Green", cid: 29, colorHex: "", colorShort: "MOS" },
  { name: "Hunter Green", cid: 23, colorHex: "", colorShort: "HGR" },
  { name: "Clementine", cid: 202, colorHex: "", colorShort: "CLM" },
  { name: "Red", cid: 39, colorHex: "", colorShort: "RED" },
  { name: "Burgundy", cid: 9, colorHex: "", colorShort: "BUR" },
  { name: "Peony", cid: 108, colorHex: "", colorShort: "PNY" },
  { name: "Kona", cid: 25, colorHex: "", colorShort: "KON" },
  { name: "Taupe", cid: 179, colorHex: "", colorShort: "TUP" },
  { name: "Pewter", cid: 34, colorHex: "", colorShort: "PGR" },
  { name: "Charcoal", cid: 11, colorHex: "", colorShort: "CGR" },
  { name: "Black", cid: 8, colorHex: "", colorShort: "BLK" },
  { name: "White", cid: 46, colorHex: "", colorShort: "WHT" },
  { name: "Gold", cid: 28, colorHex: "", colorShort: "MGD" },
  { name: "Platinum", cid: 193, colorHex: "", colorShort: "PLA" },
  { name: "Marine Blue", cid: 472, colorHex: "", colorShort: "MBL" },
  { name: "Clover", cid: 336, colorHex: "", colorShort: "CLO" },
  { name: "Graphite", cid: 479, colorHex: "", colorShort: "GRP" },
  { name: "Mushroom", cid: 468, colorHex: "", colorShort: "MUS" },
  { name: "Silver", cid: 43, colorHex: "", colorShort: "SIL" },
  { name: "Polished Copper", cid: 15, colorHex: "", colorShort: "COP" },
  { name: "Blind Embossed", cid: 6, colorHex: "#aaabad", colorShort: "BEM" },
  // {name:"Midnight", cid:421},
  // ,
  // {name:"Blind Debossed", cid:195}
];
export const colors = {
  Thermography: [
    {
      colorId: 8,
      colorShort: "BLK",
      colorName: "Black",
      colorHex: "#2b2b2b",
      colorRGB: "rgb(43, 43, 43)",
    },
    {
      colorId: 30,
      colorShort: "NAV",
      colorName: "Navy",
      colorHex: "#43505f",
      colorRGB: "rgb(67, 80, 95)",
    },
    {
      colorId: 38,
      colorShort: "RBL",
      colorName: "Regent Blue",
      colorHex: "#3a547c",
      colorRGB: "rgb(58, 84, 124)",
    },
    {
      colorId: 472,
      colorShort: "MBL",
      colorName: "Marine Blue",
      colorHex: "#698eaa",
      colorRGB: "rgb(105, 142, 170)",
    },
    {
      colorId: 31,
      colorShort: "NBL",
      colorName: "Newport Blue",
      colorHex: "#2e89ab",
      colorRGB: "rgb(46, 137, 171)",
    },
    {
      colorId: 20,
      colorShort: "FBL",
      colorName: "French Blue",
      colorHex: "#5d8089",
      colorRGB: "rgb(93, 128, 137)",
    },
    {
      colorId: 184,
      colorShort: "PCK",
      colorName: "Peacock",
      colorHex: "#759ba3",
      colorRGB: "rgb(117, 155, 163)",
    },
    {
      colorId: 34,
      colorShort: "PGR",
      colorName: "Pewter",
      colorHex: "#9b9e98",
      colorRGB: "rgb(155, 158, 152)",
    },
    {
      colorId: 479,
      colorShort: "GRP",
      colorName: "Graphite",
      colorHex: "#767569",
      colorRGB: "rgb(118, 117, 105)",
    },
    {
      colorId: 23,
      colorShort: "HGR",
      colorName: "Hunter Green",
      colorHex: "#4f6d5a",
      colorRGB: "rgb(79, 109, 90)",
    },
    {
      colorId: 336,
      colorShort: "CLO",
      colorName: "Clover",
      colorHex: "#56844e",
      colorRGB: "rgb(86, 132, 78)",
    },
    {
      colorId: 29,
      colorShort: "MOS",
      colorName: "Moss Green",
      colorHex: "#8a8536",
      colorRGB: "rgb(138, 133, 54)",
    },
    {
      colorId: 25,
      colorShort: "KON",
      colorName: "Kona",
      colorHex: "#795453",
      colorRGB: "rgb(121, 84, 83)",
    },
    {
      colorId: 9,
      colorShort: "BUR",
      colorName: "Burgundy",
      colorHex: "#691c32",
      colorRGB: "rgb(105, 28, 50)",
    },
    {
      colorId: 39,
      colorShort: "RED",
      colorName: "Red",
      colorHex: "#ce233b",
      colorRGB: "rgb(206, 35, 59)",
    },
    {
      colorId: 108,
      colorShort: "PNY",
      colorName: "Peony",
      colorHex: "#ff99a7",
      colorRGB: "rgb(255, 153, 167)",
    },
    {
      colorId: 202,
      colorShort: "CLM",
      colorName: "Clementine",
      colorHex: "#ff6d2d",
      colorRGB: "rgb(255, 109, 45)",
    },
    {
      colorId: 468,
      colorShort: "MUS",
      colorName: "Mushroom",
      colorHex: "#9d9170",
      colorRGB: "rgb(157, 145, 112)",
    },
    {
      colorId: 179,
      colorShort: "TUP",
      colorName: "Taupe",
      colorHex: "#776d65",
      colorRGB: "rgb(119, 109, 101)",
    },
    {
      colorId: 11,
      colorShort: "CGR",
      colorName: "Charcoal",
      colorHex: "#5e625f",
      colorRGB: "rgb(94, 98, 95)",
    },
    {
      colorId: 15,
      colorShort: "COP",
      colorName: "Polished Copper",
      colorHex: "#b3826c",
      colorRGB: "rgb(179, 130, 108)",
    },
    {
      colorId: 28,
      colorShort: "MGD",
      colorName: "Gold",
      colorHex: "#b98d56",
      colorRGB: "rgb(185, 141, 86)",
    },
    {
      colorId: 43,
      colorShort: "SIL",
      colorName: "Silver",
      colorHex: "#b1b4b3",
      colorRGB: "rgb(177, 180, 179)",
    },
    {
      colorId: 193,
      colorShort: "PLA",
      colorName: "Platinum",
      colorHex: "#8c8677",
      colorRGB: "rgb(140, 134, 119)",
    },
  ],
  Engraving: [
    {
      colorId: 43,
      colorShort: "WHT",
      colorName: "White",
      colorHex: "#ffffff",
      colorRGB: "rgb(255, 255, 255)",
    },
    {
      colorId: 8,
      colorShort: "BLK",
      colorName: "Black",
      colorHex: "#2b2b2b",
      colorRGB: "rgb(43, 43, 43)",
    },
    {
      colorId: 30,
      colorShort: "NAV",
      colorName: "Navy",
      colorHex: "#365072",
      colorRGB: "rgb(54, 80, 114)",
    },
    {
      colorId: 38,
      colorShort: "RBL",
      colorName: "Regent Blue",
      colorHex: "#45649d",
      colorRGB: "rgb(69, 100, 157)",
    },
    {
      colorId: 472,
      colorShort: "MBL",
      colorName: "Marine Blue",
      colorHex: "#667fa4",
      colorRGB: "rgb(102, 127, 164)",
    },
    {
      colorId: 31,
      colorShort: "NBL",
      colorName: "Newport Blue",
      colorHex: "#7497ba",
      colorRGB: "rgb(116, 151, 186)",
    },
    {
      colorId: 20,
      colorShort: "FBL",
      colorName: "French Blue",
      colorHex: "#668ea3",
      colorRGB: "rgb(102, 142, 163)",
    },
    {
      colorId: 184,
      colorShort: "PCK",
      colorName: "Peacock",
      colorHex: "#759ba3",
      colorRGB: "rgb(117, 155, 163)",
    },
    {
      colorId: 34,
      colorShort: "PGR",
      colorName: "Pewter",
      colorHex: "#95a8a7",
      colorRGB: "rgb(149, 168, 167)",
    },
    {
      colorId: 479,
      colorShort: "GRP",
      colorName: "Graphite",
      colorHex: "#6b6d5f",
      colorRGB: "rgb(107, 109, 95)",
    },
    {
      colorId: 23,
      colorShort: "HGR",
      colorName: "Hunter Green",
      colorHex: "#3d6b45",
      colorRGB: "rgb(61, 107, 69)",
    },
    {
      colorId: 336,
      colorShort: "CLO",
      colorName: "Clover",
      colorHex: "#78aa6d",
      colorRGB: "rgb(120, 170, 109)",
    },
    {
      colorId: 29,
      colorShort: "MOS",
      colorName: "Moss Green",
      colorHex: "#909044",
      colorRGB: "rgb(144, 144, 68)",
    },
    {
      colorId: 25,
      colorShort: "KON",
      colorName: "Kona",
      colorHex: "#795453",
      colorRGB: "rgb(121, 84, 83)",
    },
    {
      colorId: 9,
      colorShort: "BUR",
      colorName: "Burgundy",
      colorHex: "#8a1e41",
      colorRGB: "rgb(138, 30, 65)",
    },
    {
      colorId: 39,
      colorShort: "RED",
      colorName: "Red",
      colorHex: "#db3438",
      colorRGB: "rgb(219, 52, 56)",
    },
    {
      colorId: 108,
      colorShort: "PNY",
      colorName: "Peony",
      colorHex: "#ffb9c8",
      colorRGB: "rgb(255, 185, 200)",
    },
    {
      colorId: 202,
      colorShort: "CLM",
      colorName: "Clementine",
      colorHex: "#ff8136",
      colorRGB: "rgb(255, 129, 54)",
    },
    {
      colorId: 468,
      colorShort: "MUS",
      colorName: "Mushroom",
      colorHex: "#a99f8b",
      colorRGB: "rgb(169, 159, 139)",
    },
    {
      colorId: 179,
      colorShort: "TUP",
      colorName: "Taupe",
      colorHex: "#958f8b",
      colorRGB: "rgb(149, 143, 139)",
    },
    {
      colorId: 11,
      colorShort: "CGR",
      colorName: "Charcoal",
      colorHex: "#7c7d7f",
      colorRGB: "rgb(124, 125, 127)",
    },
    {
      colorId: 15,
      colorShort: "COP",
      colorName: "Polished Copper",
      colorHex: "#b9825d",
      colorRGB: "rgb(185, 130, 93)",
    },
    {
      colorId: 28,
      colorShort: "MGD",
      colorName: "Gold",
      colorHex: "#bea079",
      colorRGB: "rgb(190, 160, 121)",
    },
    {
      colorId: 43,
      colorShort: "SIL",
      colorName: "Silver",
      colorHex: "#bcbcb4",
      colorRGB: "rgb(188, 188, 180)",
    },
    {
      colorId: 193,
      colorShort: "PLA",
      colorName: "Platinum",
      colorHex: "#7c7d7f",
      colorRGB: "rgb(124, 125, 127)",
    },
  ],
  Letterpress: [
    {
      colorId: 43,
      colorShort: "WHT",
      colorName: "White",
      colorHex: "#ffffff",
      colorRGB: "rgb(255, 255, 255)",
    },
    {
      colorId: 8,
      colorShort: "BLK",
      colorName: "Black",
      colorHex: "#585955",
      colorRGB: "rgb(88, 89, 85)",
    },
    {
      colorId: 30,
      colorShort: "NAV",
      colorName: "Navy",
      colorHex: "#5e6374",
      colorRGB: "rgb(94, 99, 116)",
    },
    {
      colorId: 38,
      colorShort: "RBL",
      colorName: "Regent Blue",
      colorHex: "#495c87",
      colorRGB: "rgb(73, 92, 135)",
    },
    {
      colorId: 472,
      colorShort: "MBL",
      colorName: "Marine Blue",
      colorHex: "#8298b9",
      colorRGB: "rgb(130, 152, 185)",
    },
    {
      colorId: 31,
      colorShort: "NBL",
      colorName: "Newport Blue",
      colorHex: "#6194c1",
      colorRGB: "rgb(97, 148, 193)",
    },
    {
      colorId: 20,
      colorShort: "FBL",
      colorName: "French Blue",
      colorHex: "#668ea3",
      colorRGB: "rgb(102, 142, 163)",
    },
    {
      colorId: 184,
      colorShort: "PCK",
      colorName: "Peacock",
      colorHex: "#759ba3",
      colorRGB: "rgb(117, 155, 163)",
    },
    {
      colorId: 34,
      colorShort: "PGR",
      colorName: "Pewter",
      colorHex: "#9a9ea1",
      colorRGB: "rgb(154, 158, 161)",
    },
    {
      colorId: 479,
      colorShort: "GRP",
      colorName: "Graphite",
      colorHex: "#9b9e98",
      colorRGB: "rgb(155, 158, 152)",
    },
    {
      colorId: 23,
      colorShort: "HGR",
      colorName: "Hunter Green",
      colorHex: "#4f6d5a",
      colorRGB: "rgb(79, 109, 90)",
    },
    {
      colorId: 336,
      colorShort: "CLO",
      colorName: "Clover",
      colorHex: "#8ea836",
      colorRGB: "rgb(142, 168, 54)",
    },
    {
      colorId: 29,
      colorShort: "MOS",
      colorName: "Moss Green",
      colorHex: "#9c9f61",
      colorRGB: "rgb(156, 159, 97)",
    },
    {
      colorId: 25,
      colorShort: "KON",
      colorName: "Kona",
      colorHex: "#795453",
      colorRGB: "rgb(121, 84, 83)",
    },
    {
      colorId: 9,
      colorShort: "BUR",
      colorName: "Burgundy",
      colorHex: "#7e5255",
      colorRGB: "rgb(126, 82, 85)",
    },
    {
      colorId: 39,
      colorShort: "RED",
      colorName: "Red",
      colorHex: "#d7282f",
      colorRGB: "rgb(215, 40, 47)",
    },
    {
      colorId: 108,
      colorShort: "PNY",
      colorName: "Peony",
      colorHex: "#ff99a7",
      colorRGB: "rgb(255, 153, 167)",
    },
    {
      colorId: 202,
      colorShort: "CLM",
      colorName: "Clementine",
      colorHex: "#ff854f",
      colorRGB: "rgb(255, 133, 79)",
    },
    {
      colorId: 468,
      colorShort: "MUS",
      colorName: "Mushroom",
      colorHex: "#a99f8b",
      colorRGB: "rgb(169, 159, 139)",
    },
    {
      colorId: 179,
      colorShort: "TUP",
      colorName: "Taupe",
      colorHex: "#776d65",
      colorRGB: "rgb(119, 109, 101)",
    },
    {
      colorId: 11,
      colorShort: "CGR",
      colorName: "Charcoal",
      colorHex: "#666666",
      colorRGB: "rgb(102, 102, 102)",
    },
    {
      colorId: 15,
      colorShort: "COP",
      colorName: "Polished Copper",
      colorHex: "#dbaa9b",
      colorRGB: "rgb(219, 170, 155)",
    },
    {
      colorId: 28,
      colorShort: "MGD",
      colorName: "Gold",
      colorHex: "#b2936f",
      colorRGB: "rgb(178, 147, 111)",
    },
    {
      colorId: 43,
      colorShort: "SIL",
      colorName: "Silver",
      colorHex: "#c6c0b8",
      colorRGB: "rgb(198, 192, 184)",
    },
    {
      colorId: 193,
      colorShort: "PLA",
      colorName: "Platinum",
      colorHex: "#afa89c",
      colorRGB: "rgb(175, 168, 156)",
    },
  ],
  Digital: [
    {
      colorId: 43,
      colorShort: "WHT",
      colorName: "White",
      colorHex: "#ffffff",
      colorRGB: "rgb(255, 255, 255)",
    },
    {
      colorId: 8,
      colorShort: "BLK",
      colorName: "Black",
      colorHex: "#2b2b2b",
      colorRGB: "rgb(43, 43, 43)",
    },
    {
      colorId: 30,
      colorShort: "NAV",
      colorName: "Navy",
      colorHex: "#515a6d",
      colorRGB: "rgb(81, 90, 109)",
    },
    {
      colorId: 38,
      colorShort: "RBL",
      colorName: "Regent Blue",
      colorHex: "#365888",
      colorRGB: "rgb(54, 88, 136)",
    },
    {
      colorId: 472,
      colorShort: "MBL",
      colorName: "Marine Blue",
      colorHex: "#b6cfbf",
      colorRGB: "rgb(182, 207, 191)",
    },
    {
      colorId: 31,
      colorShort: "NBL",
      colorName: "Newport Blue",
      colorHex: "#659dc7",
      colorRGB: "rgb(101, 157, 199)",
    },
    {
      colorId: 20,
      colorShort: "FBL",
      colorName: "French Blue",
      colorHex: "#668ea3",
      colorRGB: "rgb(102, 142, 163)",
    },
    {
      colorId: 184,
      colorShort: "PCK",
      colorName: "Peacock",
      colorHex: "#759ba3",
      colorRGB: "rgb(117, 155, 163)",
    },
    {
      colorId: 34,
      colorShort: "PGR",
      colorName: "Pewter",
      colorHex: "#9a9ea1",
      colorRGB: "rgb(154, 158, 161)",
    },
    {
      colorId: 479,
      colorShort: "GRP",
      colorName: "Graphite",
      colorHex: "#a99f8b",
      colorRGB: "rgb(169, 159, 139)",
    },
    {
      colorId: 23,
      colorShort: "HGR",
      colorName: "Hunter Green",
      colorHex: "#4f6d5a",
      colorRGB: "rgb(79, 109, 90)",
    },
    {
      colorId: 336,
      colorShort: "CLO",
      colorName: "Clover",
      colorHex: "#64a295",
      colorRGB: "rgb(100, 162, 1499)",
    },
    {
      colorId: 29,
      colorShort: "MOS",
      colorName: "Moss Green",
      colorHex: "#909044",
      colorRGB: "rgb(144, 144, 68)",
    },
    {
      colorId: 25,
      colorShort: "KON",
      colorName: "Kona",
      colorHex: "#82605d",
      colorRGB: "rgb(130, 96, 93)",
    },
    {
      colorId: 9,
      colorShort: "BUR",
      colorName: "Burgundy",
      colorHex: "#8a2432",
      colorRGB: "rgb(138, 36, 50)",
    },
    {
      colorId: 39,
      colorShort: "RED",
      colorName: "Red",
      colorHex: "#cf3339",
      colorRGB: "rgb(207, 51, 57)",
    },
    {
      colorId: 108,
      colorShort: "PNY",
      colorName: "Peony",
      colorHex: "#ff6d81",
      colorRGB: "rgb(255, 109, 129)",
    },
    {
      colorId: 202,
      colorShort: "CLM",
      colorName: "Clementine",
      colorHex: "#ff8136",
      colorRGB: "rgb(255, 129, 54)",
    },
    {
      colorId: 468,
      colorShort: "MUS",
      colorName: "Mushroom",
      colorHex: "#bcafa8",
      colorRGB: "rgb(188, 175, 168)",
    },
    {
      colorId: 179,
      colorShort: "TUP",
      colorName: "Taupe",
      colorHex: "#8a7e78",
      colorRGB: "rgb(138, 126, 120)",
    },
    {
      colorId: 11,
      colorShort: "CGR",
      colorName: "Charcoal",
      colorHex: "#737574",
      colorRGB: "rgb(115, 117, 116)",
    },
    {
      colorId: 28,
      colorShort: "MGD",
      colorName: "Gold",
      colorHex: "#b2936f",
      colorRGB: "rgb(178, 147, 111)",
    },
    {
      colorId: 43,
      colorShort: "SIL",
      colorName: "Silver",
      colorHex: "#dbaa9b",
      colorRGB: "rgb(219, 170, 155)",
    },
    {
      colorId: 193,
      colorShort: "PLA",
      colorName: "Platinum",
      colorHex: "#8c8677",
      colorRGB: "rgb(140, 134, 119)",
    },
  ],
  Flat: [
    {
      colorId: 43,
      colorShort: "WHT",
      colorName: "White",
      colorHex: "#ffffff",
      colorRGB: "rgb(255, 255, 255)",
    },
    {
      colorId: 8,
      colorShort: "BLK",
      colorName: "Black",
      colorHex: "#2b2b2b",
      colorRGB: "rgb(43, 43, 43)",
    },
    {
      colorId: 30,
      colorShort: "NAV",
      colorName: "Navy",
      colorHex: "#515a6d",
      colorRGB: "rgb(81, 90, 109)",
    },
    {
      colorId: 38,
      colorShort: "RBL",
      colorName: "Regent Blue",
      colorHex: "#365888",
      colorRGB: "rgb(54, 88, 136)",
    },
    {
      colorId: 472,
      colorShort: "MBL",
      colorName: "Marine Blue",
      colorHex: "#b6cfbf",
      colorRGB: "rgb(182, 207, 191)",
    },
    {
      colorId: 31,
      colorShort: "NBL",
      colorName: "Newport Blue",
      colorHex: "#659dc7",
      colorRGB: "rgb(101, 157, 199)",
    },
    {
      colorId: 20,
      colorShort: "FBL",
      colorName: "French Blue",
      colorHex: "#668ea3",
      colorRGB: "rgb(102, 142, 163)",
    },
    {
      colorId: 184,
      colorShort: "PCK",
      colorName: "Peacock",
      colorHex: "#759ba3",
      colorRGB: "rgb(117, 155, 163)",
    },
    {
      colorId: 34,
      colorShort: "PGR",
      colorName: "Pewter",
      colorHex: "#9a9ea1",
      colorRGB: "rgb(154, 158, 161)",
    },
    {
      colorId: 479,
      colorShort: "GRP",
      colorName: "Graphite",
      colorHex: "#a99f8b",
      colorRGB: "rgb(169, 159, 139)",
    },
    {
      colorId: 23,
      colorShort: "HGR",
      colorName: "Hunter Green",
      colorHex: "#4f6d5a",
      colorRGB: "rgb(79, 109, 90)",
    },
    {
      colorId: 336,
      colorShort: "CLO",
      colorName: "Clover",
      colorHex: "#64a295",
      colorRGB: "rgb(100, 162, 1499)",
    },
    {
      colorId: 29,
      colorShort: "MOS",
      colorName: "Moss Green",
      colorHex: "#909044",
      colorRGB: "rgb(144, 144, 68)",
    },
    {
      colorId: 25,
      colorShort: "KON",
      colorName: "Kona",
      colorHex: "#82605d",
      colorRGB: "rgb(130, 96, 93)",
    },
    {
      colorId: 9,
      colorShort: "BUR",
      colorName: "Burgundy",
      colorHex: "#8a2432",
      colorRGB: "rgb(138, 36, 50)",
    },
    {
      colorId: 39,
      colorShort: "RED",
      colorName: "Red",
      colorHex: "#cf3339",
      colorRGB: "rgb(207, 51, 57)",
    },
    {
      colorId: 108,
      colorShort: "PNY",
      colorName: "Peony",
      colorHex: "#ff6d81",
      colorRGB: "rgb(255, 109, 129)",
    },
    {
      colorId: 202,
      colorShort: "CLM",
      colorName: "Clementine",
      colorHex: "#ff8136",
      colorRGB: "rgb(255, 129, 54)",
    },
    {
      colorId: 468,
      colorShort: "MUS",
      colorName: "Mushroom",
      colorHex: "#bcafa8",
      colorRGB: "rgb(188, 175, 168)",
    },
    {
      colorId: 179,
      colorShort: "TUP",
      colorName: "Taupe",
      colorHex: "#8a7e78",
      colorRGB: "rgb(138, 126, 120)",
    },
    {
      colorId: 11,
      colorShort: "CGR",
      colorName: "Charcoal",
      colorHex: "#737574",
      colorRGB: "rgb(115, 117, 116)",
    },
    {
      colorId: 28,
      colorShort: "MGD",
      colorName: "Gold",
      colorHex: "#b2936f",
      colorRGB: "rgb(178, 147, 111)",
    },
    {
      colorId: 43,
      colorShort: "SIL",
      colorName: "Silver",
      colorHex: "#dbaa9b",
      colorRGB: "rgb(219, 170, 155)",
    },
    {
      colorId: 193,
      colorShort: "PLA",
      colorName: "Platinum",
      colorHex: "#8c8677",
      colorRGB: "rgb(140, 134, 119)",
    },
  ],
  Embossing: [
    {
      colorId: 6,
      colorShort: "BEM",
      colorName: "Blind Embossed",
      colorHex: "#aaabad",
      colorRGB: "rgb(170, 171, 173)",
    },
  ],
  Foil: [],
};

export const colorIds = {
  "#7497ba": 31,
  "#45649d": 88,
  "#365072": 30,
  "#759ba3": 184,
  "#668ea3": 20,
  "#909044": 29,
  "#3d6b45": 23,
  "#ff8136": 202,
  "#db3438": 39,
  "#8a1e41": 9,
  "#ffb9c8": 108,
  "#795453": 25,
  "#958f8b": 179,
  "#95a8a7": 34,
  "#7c7d7f": 11,
  "#2b2b2b": 8,
  "#ffffff": 46,
  "#cda36d": 28,
  //"#7c7d7f": 193,
  "#667fa4": 472,
  "#78aa6d": 336,
  "#6b6d5f": 479,
  "#a99f8b": 468,
  "#bcbcb4": 43,
  "#b9825d": 15,
};

export const attribute = (
  customAttributes,
  attributeCode,
  productAttributes
) => {
  if (productAttributes.items && customAttributes) {
    let result = customAttributes.filter((element) => {
      return element.attributeCode === attributeCode;
    });
    if (result.length > 0) {
      let val = result[0].value; //254 //uom
      let newValue = productAttributes.items.filter((elements) => {
        return elements.attributeCode === attributeCode;
      });
      let filter = newValue[0].options.filter((elementNew) => {
        return elementNew.value === val;
      });
      if (filter.length > 0) {
        return filter[0].label;
      }
    }
  }
};

export const customAttributeValue = (customAttributes, attributeCode) => {
  let result = customAttributes.filter((element) => {
    return element.attributeCode === attributeCode;
  });
  if (result[0]) return result[0].value;
  else return "";
};

export const getLable = (customAttributes, attributeCode) => {
  let result = customAttributes.filter((element) => {
    return element.attributeCode === attributeCode;
  });
  if (result[0]) return result[0].label;
  else return "";
};
export const priceFormatter = (price) => {
  if (price != undefined) {
    price = price.toString();
    let decimals = price.split(".")[1];
    if (!Number(decimals) || isNaN(decimals) || decimals.length == 1)
      //if 2 or 2.1
      return Number(price).toFixed(2);
    else if (decimals.length > 1)
      // if 2.34 or 2.56565
      return Number(price.match(/^-?\d+(?:\.\d{0,2})?/)[0]);
  }
};
function getlabelValue(customAttributes, attributeCode) {
  let result = customAttributes.filter((element) => {
    return element.attributeCode === attributeCode;
  });
  if (result[0]) return result[0].label;
  else return "Crane";
}
export const createCagetoryChildParents = async (categoryListIdWise, catId) => {
  let newArray = [];
  let newObj = await getParentId(categoryListIdWise, catId);
  if (newObj.length > 0) {
    let obj = {
      text: newObj[0].name,
    };
    newArray.push(obj);
    while (newObj[0].parentId != 2) {
      newObj = await getParentId(categoryListIdWise, newObj[0].parentId);
      obj = {
        text: newObj[0].name,
      };
      newArray.push(obj);
    }
  }

  let actualArray = newArray.reverse();
  var catObj = {};
  for (let i = 0; actualArray.length > i; i++) {
    if (i === 0) {
      catObj["item_category"] = actualArray[i].text;
    } else {
      catObj["item_category" + i] = actualArray[i].text;
    }
  }

  return catObj;
};
export const getParentId = async (categoryListIdWise, categoryId) => {
  return categoryListIdWise.filter((element) => {
    return element.id === categoryId;
  });
};

export const googleAanalyticsEventSaveForLatter = async (
  productDetails,
  action
) => {
  let personalizeType = "builder";
  let nature = "personalized";
  let gtag = window.gtag ? window.gtag : null;

  /* GA4 NEW CODE */
  if (window.dataLayer) window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: action,
    ecommerce: {
      nature: nature,
      personality: personalizeType,
      items: [
        {
          item_id: productDetails.sku,
          item_name: productDetails.name,
          item_brand: "crane",
          personalize: personalizeType,
        },
      ],
    },
  });
  console.log("GA4 NEW CODE CALLED", action);
};

export const googleAanalytics = async (
  res,
  productDetails,
  qtyNew,
  action,
  type,
  categoyrListIdWise
) => {
  let catName = "";
  let childParentsObj = {};
  let catId = 0;
  if (getCookieByName(res.item_id)) {
    catName = getCookieByName(res.item_id);
    catId = catName.split(":")[1];
    catName = catName.split(":")[0];
    childParentsObj = await createCagetoryChildParents(
      categoyrListIdWise.childrenData[0].childrenData,
      Number(catId)
    );
    localStorage.setItem(
      "quote_item_id" + res.item_id,
      JSON.stringify(childParentsObj)
    );
  } else if (productDetails.extensionAttribute.catagoryLinks[0]) {
    catName = productDetails.extensionAttribute.catagoryLinks[0].categoryName;
    childParentsObj = { item_category: catName };
    localStorage.setItem(
      "quote_item_id" + res.item_id,
      JSON.stringify(childParentsObj)
    );
  }

  let gtag = window.gtag ? window.gtag : null;
  if (gtag) {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: action,
      ecommerce: {
        nature: "personalized",
        personality: "builder",
        currency: "USD",
        value: Number(res.price) * qtyNew,
        items: [
          {
            item_id: productDetails.sku,
            item_name: productDetails.name,
            item_brand: getlabelValue(productDetails.customAttribute, "brand"),
            item_category: catName,
            price: Number(res.price),
            quantity: qtyNew,
            ...childParentsObj,
          },
        ],
      },
    });
    console.log("itemGA4 List add_to_cart studio", {
      item_id: productDetails.sku,
      item_name: productDetails.name,
      item_brand: getlabelValue(productDetails.customAttribute, "brand"),
      item_category: catName,
      price: res.price,
      quantity: qtyNew,
      ...childParentsObj,
    });
    console.log("GA4 NEW CODE CALLED", action);
    /* GA4 NEW CODE END */
  }
};
export const googleAanalyticsEventRemoveCart = (action, productDetails) => {
  let gtag = window.gtag ? window.gtag : null;

  console.log("google analytics called ", action);

  /* GA4 NEW CODE */
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  localStorage.removeItem("quote_item_id" + productDetails.itemId);

  window.dataLayer.push({
    event: action,
    currency: "USD",
    value: Number(productDetails.price) * productDetails.qty,
    ecommerce: {
      items: [
        {
          item_id: productDetails.sku,
          item_name: productDetails.name,
          price: Number(productDetails.price),
          quantity: productDetails.qty,
        },
      ],
    },
  });
  console.log("GA4 NEW CODE CALLED", action);
  /* GA4 NEW CODE END */
};
export const altCharCodes = [
  { char: "A", altCode: "\u00CC" },
  { char: "B", altCode: "\u00CD" },
  { char: "C", altCode: "\u02D8" },
  { char: "D", altCode: "\u00CF" },
  { char: "E", altCode: "\u00D0" },
  { char: "F", altCode: "\u00D1" },
  { char: "G", altCode: "\u00D2" },
  { char: "H", altCode: "\u00D3" },
  { char: "I", altCode: "\u00D4" },
  { char: "J", altCode: "\u00D5" },
  { char: "K", altCode: "\u00D6" },
  { char: "L", altCode: "\u0152" },
  { char: "M", altCode: "\u00D8" },
  { char: "N", altCode: "\u00D9" },
  { char: "O", altCode: "\u00DA" },
  { char: "P", altCode: "\u00DB" },
  { char: "Q", altCode: "\u00DC" },
  { char: "R", altCode: "\u00DD" },
  { char: "S", altCode: "\u00DE" },
  { char: "T", altCode: "\u00DF" },
  { char: "U", altCode: "\u00E0" },
  { char: "V", altCode: "\u00E1" },
  { char: "W", altCode: "\u00E2" },
  { char: "X", altCode: "\u00E3" },
  { char: "Y", altCode: "\u00E4" },
  { char: "Z", altCode: "\u00E5" },
];
/* eslint-disable */
export const checkPromotion = ({
  sku,
  quantity,
  product,
  productPartsObjects,
}) => {
  for (let i = 0; i < promotions.length; i++) {
    let promotion = promotions[i];
    let rules = promotion.promotion_rule;
    promotion.appliedRules = [];
    promotion.exclusive = [];
    promotion.exclusiveRules = [];
    for (let m = 0; m < rules.length; m++) {
      let rule = rules[m];
      let conditions = rule.conditions;
      rule.allCondition = false;

      if (rule.rule_type == "exclusive") promotion.exclusive.push("true");

      rule.evaluateConditions = [];
      for (let j = 0; j < conditions.length; j++) {
        let condition = conditions[j];
        switch (condition.variable) {
          case "product_sku":
            rule.allCondition = evaluatePredicate(
              condition.predicate,
              condition.value.toLowerCase(),
              sku.toLowerCase()
            );
            break;
          case "product_tag":
            let tags = condition.value.split(",");
            if (product.tags) {
              const found = tags.some((r) => product.tags.indexOf(r) >= 0);
              if (found) rule.allCondition = true;
              else rule.allCondition = false;
            }
            break;

          case "quantity":
            rule.allCondition = evaluatePredicate(
              condition.predicate,
              condition.value,
              quantity
            );

            break;

          case "Product_part_with_liner":
            let partName = condition.sub_variable;
            let objects = productPartsObjects.filter(
              (obj) => obj.partName.toLowerCase() == partName.toLowerCase()
            )[0];
            if (
              objects &&
              objects.linerObject &&
              objects.linerObject.selectedLiner
            ) {
              rule.allCondition = true;
            } else rule.allCondition = false;
            break;

          case "count_of_product_part_press_passes":
            let name = condition.sub_variable;
            let partObjects = productPartsObjects.filter(
              (obj) => obj.partName.toLowerCase() == name.toLowerCase()
            )[0];
            if (partObjects && partObjects.processes) {
              let processes = partObjects.processes;
              if (Object.keys(processes).length)
                rule.allCondition = evaluatePredicate(
                  condition.predicate,
                  condition.value,
                  processes[Object.keys(processes)[0]].passes
                );
              else
                rule.allCondition = evaluatePredicate(
                  condition.predicate,
                  condition.value,
                  0
                );
            } else rule.allCondition = false;
            break;
          case "count_of_product_press_passes":
            let passes = 0;
            let processesObject = {};
            for (let k = 0; k < productPartsObjects.length; k++) {
              let partObjects = productPartsObjects[k];
              if (partObjects.processes) {
                let processes = partObjects.processes;
                for (const [key, value] of Object.entries(processes)) {
                  if (!processesObject.hasOwnProperty(key)) {
                    processesObject[key] = [];
                  }
                  for (let p = 0; p < value.colors.length; p++) {
                    let color = value.colors[p];
                    if (!processesObject[key].includes(color)) {
                      passes = passes + 1;
                      processesObject[key].push(color);
                    }
                  }
                }
              }
            }
            rule.allCondition = evaluatePredicate(
              condition.predicate,
              condition.value,
              passes
            );
            break;
        }
        rule.evaluateConditions.push(rule.allCondition);
      }
      if (
        rule.evaluateConditions.length > 0 &&
        !rule.evaluateConditions.includes(false)
      ) {
        promotion.appliedRules.push(rule);
        promotion.applied = true;
        if (rule.rule_type == "exclusive") {
          promotion.exclusiveRules.push("true");
        }
      }
    }
  }
  return promotions.filter((promotion) => {
    let startDate = promotion.start_dt;
    let endDate = promotion.end_dt;
    let currentDate = new Date();
    console.log(new Date(endDate).toJSON().slice(0, 10));
    if (
      new Date(endDate).getTime() > currentDate.getTime() &&
      new Date(startDate).getTime() <= currentDate.getTime()
    ) {
      return (
        promotion.applied === true &&
        promotion.exclusiveRules.length == promotion.exclusive.length
      );
    }
  })[0];
};

export const evaluatePredicate = (operator, compareValue, value) => {
  let boolean = true;
  switch (operator) {
    case ">":
      if (Number(value) > Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case "<":
      if (Number(value) < Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case "<=":
      if (Number(value) <= Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case ">=":
      if (Number(value) >= Number(compareValue)) boolean = true;
      else boolean = false;
      break;
    case "==":
      if (Number(value) == Number(compareValue)) boolean = true;
      else boolean = false;
      break;

    case "=":
      if (Number(value) === Number(compareValue)) boolean = true;
      else boolean = false;
      break;

    case "IN":
      let values = compareValue.toLowerCase().split(",");
      if (values.includes(value.toLowerCase())) boolean = true;
      else boolean = false;
      break;
  }
  return boolean;
};
//refresh handler
export const handleBeforeUnload = async (e) => {
  let userAgent = window.navigator.userAgent;

  //except chrome we need to do preventDefault for evt
  if (!userAgent.match(/chrome|chromium|crios/i)) {
    e.preventDefault();
  }
  e = e || window.event;
  // For IE and Firefox prior to version 4
  if (e) {
    e.returnValue = "sure?";
  }
  await window.crPdpInstance.saveUserChanges();
  // For Safari
  return "sure?";
};
export const criteoViewBasket = (list) => {
  let items = [];
  for (let i = 0; i < list.length; i++) {
    let item = list[i];
    items.push({
      id: item.stockDetails.productId,
      price: item.price,
      quantity: item.qty,
    });
  }
  if (items.length > 0) {
    window.criteo_q.push({
      event: "viewBasket",
      item: items,
    });
  }

  console.log("view basket", items);
};
export const listrakCartList = (list) => {
  let items = [];
  for (let i = 0; i < list.length; i++) {
    let productDetails = list[i];
    if (window._ltk) {
      window._ltk.SCA.AddItemWithLinks(
        productDetails.sku,
        productDetails.qty,
        productDetails.price,
        productDetails.name,
        productDetails.customAttributes.image,
        productDetails.customAttributes.urlKey
      );
      window._ltk.SCA.Submit();
      console.log(
        "listrak  add to cart event called ",
        productDetails.sku,
        productDetails.qty,
        productDetails.price,
        productDetails.name,
        productDetails.customAttributes.image,
        productDetails.customAttributes.urlKey
      );
    }
  }

  console.log("view basket", items);
};
export const listrakCartListClear = () => {
  if (window._ltk) {
    window._ltk.SCA.ClearCart();
  }
  console.log("listrak Clear Cart called");
};

/// snake case to hump and hump to snake
const toCase = (stringToCaseFn) => (value) => {
  const convertFn = toCase(stringToCaseFn);
  if (value === null) {
    return null;
  } else if (typeof value === "string") {
    return stringToCaseFn(value);
  } else if (value.length !== undefined) {
    return value.map(convertFn);
  } else if (typeof value === "object") {
    const object = {};
    Object.keys(value).forEach((key) => {
      let _value = value[key];
      if (_value && typeof _value === "object" && !checkForArray(value[key])) {
        _value = convertFn(value[key]);
      }
      object[convertFn(key)] = _value;
    });
    return object;
  } else {
    return value;
  }
};

const snakeStringToCamel = (str) =>
  str.replace(/ /g, "_").replace(/_(\w)/g, (m) => m[1].toUpperCase());
export const snakeToCamel = toCase(snakeStringToCamel);

const camelStringToSnake = (str) =>
  str.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);
export const camelToSnake = toCase(camelStringToSnake);

function checkForArray(variable) {
  if (Array.isArray(variable)) {
    if (variable.length === 0) {
      return false;
    } else {
      for (let i = 0; i < variable.length; i++) {
        if (
          typeof variable[i] === "object" ||
          variable[i] === null ||
          Array.isArray(variable[i])
        ) {
          return false;
        }
      }
      return true;
    }
  } else {
    return false;
  }
}
