import { getAuthUser, setCookieByName } from "./auth";

const loginUrl = import.meta.env.VITE_APP_LOGIN_URL
  ? import.meta.env.VITE_APP_LOGIN_URL
  : "/gate/sign-in";
const homeUrl = import.meta.env.VITE_APP_ME_URL
  ? import.meta.env.VITE_APP_ME_URL + "/account-dashboard"
  : "/";

const craneInterruptedPage = import.meta.env.VITE_CRANE_INTERRUPTED_PAGE_COOKIE
  ? import.meta.env.VITE_CRANE_INTERRUPTED_PAGE_COOKIE
  : "";

const loginInterrupt = function (current_url, message) {
  setCookieByName(craneInterruptedPage, current_url);

  sessionStorage.cinterrupted_page = current_url;
  sessionStorage.cinterrupt_message = message;
  window.location.href = loginUrl;
};

const redirectToInterruptedPage = function () {
  const url = sessionStorage.cinterrupted_page;
  if (url) {
    window.location.href = url;
    sessionStorage.removeItem("cinterrupted_page");
  } else window.location.href = homeUrl;
};

const beforeEachRoute = function (to, from, next) {
  if (!to.matched.length) {
    location.href = to.path;
  }
  const requiresRole = to.meta ? to.meta.requiresRole : null;
  if (requiresRole) {
    const userAuth = getAuthUser();
    if (
      userAuth == null ||
      userAuth.grants == null ||
      !userAuth.grants.includes(requiresRole)
    ) {
      loginInterrupt(to.fullPath);
    }
  }

  next();
};
const meWrapRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .catch((error) => meHandleError(error));

const meHandleError = async (errorResponse) => {
  if (!errorResponse.ok) {
    switch (errorResponse.status) {
      case 401: {
        loginInterrupt(
          window.location.href,
          "There was an access violation caused by your request. You need to sign back in please."
        );
        break;
      }
      case 400: {
        const text_400 = await errorResponse.text();
        const data_400 = JSON.parse(text_400);
        throw data_400;
      }

      default: {
        const error_text = await errorResponse.text();
        const error_data = JSON.parse(error_text);
        throw error_data;
      }
    } //switch
  }
};

const wrapRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .catch((error) => handleError(error));

const handleError = async (errorResponse) => {
  if (!errorResponse.ok) {
    switch (errorResponse.status) {
      case 403: {
        const text_403 = await errorResponse.text();
        //console.log('error:', text_403);
        const data_403 = JSON.parse(text_403);

        throw data_403;
      }

      case 401: {
        const text_401 = await errorResponse.text();
        const data_401 = JSON.parse(text_401);
        throw data_401;
      }
      case 406:
      {
        const text_401 = await errorResponse.text();
        const data_401 = JSON.parse(text_401);
        throw data_401;
      }
      case 400: {
        const text_400 = await errorResponse.text();
        throw text_400;
      }
      case 404: {
        const text_404 = await errorResponse.text();
        const data_404 = JSON.parse(text_404);
        throw data_404;
      }
      case 500: {
        const text_500 = await errorResponse.text();
        const data_500 = JSON.parse(text_500);
        if (
          data_500.code == "22023" &&
          data_500.message.indexOf("role") >= 0 &&
          data_500.message.indexOf("does not exist")
        ) {
          loginInterrupt(
            window.location.href,
            "There was an access violation caused by your request. You need to sign back in please."
          );
        } else {
          throw data_500;
        }
        break;
      }

      default:
        throw errorResponse;
    } //switch
  }
};

//this is for those who doesnt return a response
const wrapEmptyRequest =
  (fn) =>
  (...params) =>
    fn(...params)
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response;
      })
      .catch((error) => handleError(error));

export {
  loginInterrupt,
  redirectToInterruptedPage,
  beforeEachRoute,
  wrapRequest,
  handleError,
  meWrapRequest,
  wrapEmptyRequest,
};
