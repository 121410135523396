<template>
  <v-navigation-drawer
    v-model="showUserNav"
    v-if="showUserNav"
    width="320"
    :app="showUserNav"
    :absolute="!showUserNav"
    color="primary"
    class="z-index-1000"
    tile
  >
    <v-icon
      @click.stop="showUserNav = !showUserNav"
      class="text-white pa-4 pl-8 cursor-pointer"
      >mdi-close</v-icon
    >

    <v-row class="mt-12" justify="center" v-if="inProgress">
      <v-progress-circular
        :size="60"
        color="white"
        indeterminate
      ></v-progress-circular>
    </v-row>

    <v-list class="pl-4 mobile-header-list background-transparent">
      <v-list-group v-if="userAuth" class="drop-down-icon">
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props">
            <v-list-item-title class="text-white text-uppercase h3 my-2"
              >Hi {{ userName }}</v-list-item-title
            >
          </v-list-item>
        </template>
        <v-list-item>
          <span
            class="text-white text-uppercase h4 my-2"
            @click="goToDashboard()"
            >My Account</span
          >
        </v-list-item>
        <v-list-item>
          <span @click="goToLogout()" class="text-white text-uppercase h4 my-2"
            >Logout</span
          >
        </v-list-item>
      </v-list-group>
      <v-list-item v-if="!userAuth">
        <v-list-item-content class="pa-0">
          <v-list-item-title
            class="text-white text-uppercase h3 my-2"
            @click="goToLogin()"
            >Sign-In</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
const storeFrontUrl = import.meta.env.VITE_APP_CRANE_DAILY_URL;
export default {
  name: "UserNavigationDrawer",
  props: {
    showMenu: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    // showMenu: false,
    showUserNav: false,
  }),
  watch: {
    showMenu: {
      immediate: true,
      handler() {
        this.showUserNav = this.showMenu;
      },
    },
  },
  computed: {
    inProgress: function () {
      return this.$store.state.headerCategoryList.inProgress;
    },
    userAuth: function () {
      return this.$store.state.auth.userAuth;
    },
    userName: function () {
      return this.$store.state.accountOne.one.firstname;
    },
  },
  methods: {
    goToLogout() {
      window.open(`${storeFrontUrl}/logout-success`, "_self");
    },
    goToDashboard() {
      window.open(`${storeFrontUrl}/me/account-dashboard`, "_self");
    },
    goToLogin() {
      window.open(`${storeFrontUrl}/gate/sign-in`, "_self");
    },
  },
};
</script>
