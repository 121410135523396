import { Base64 } from 'js-base64';

const spAdminAuthName = import.meta.env.VITE_APP_SP_ADMIN_AUTH_NAME?import.meta.env.VITE_APP_SP_ADMIN_AUTH_NAME:'cr_admin_auth';
const spAuthName = import.meta.env.VITE_APP_SP_AUTH_NAME?import.meta.env.VITE_APP_SP_AUTH_NAME:'cr_cauth';
console.log("spAdminAuthName",spAdminAuthName)
console.log("spAuthName",spAuthName)
// cr_cauth
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
const getAuthUser = function() {
    // return authorization header with jwt token
    const userAuthEncoded = cookies.get(spAuthName);

    if (userAuthEncoded === null) {
        return null;
    }

    const userAuth = JSON.parse(Base64.decode(userAuthEncoded));
    return userAuth;
}

const getAdminAuthUser = function() {   
    const userAuthEncoded = cookies.get(spAdminAuthName);

    if (userAuthEncoded === null) {
        return null;
    }
    const userAuth = JSON.parse(Base64.decode(userAuthEncoded));
    return userAuth;
}

const getCookieByName = function(name) {    
   return cookies.get(name);
}

const setCookieByName = function(name,value) {    
    cookies.set(name, value, '168h', null, getDomain(window.location.hostname))
 }

const setCookieByTime = function(name,value,time) {    
    cookies.set(name, value, time, null, getDomain(window.location.hostname))
 }

const getDomain = function(domain) {
    const s = domain.split('.');
    if (s.length < 3) {
        return domain;
    }
    return s.slice(1).join('.');
}

const setAuthUser = function(userAuth) {
    const userAuthEncoded = Base64.encode(JSON.stringify(userAuth));
    cookies.set(spAuthName, userAuthEncoded, '30d', null, getDomain(window.location.hostname));
}
const setAdminAuthUser = function(userAuth) {
    const userAuthEncoded = Base64.encode(JSON.stringify(userAuth));
    cookies.set(spAdminAuthName, userAuthEncoded, '4h', null, getDomain(window.location.hostname));
}

const resetAuthUser = function() {    
    const au = getAuthUser();
    if (au === null) {
        return false;
    }
    cookies.remove(spAuthName, null,getDomain(window.location.hostname));
}

const resetAdminAuthUser = function() {    
    const au = getAuthUser();
    if (au === null) {
        return false;
    }
    cookies.remove(spAdminAuthName, null,getDomain(window.location.hostname));
}

const authHeader = function() {
    const userAuth = getAuthUser();
    if (userAuth) {
        return { 'Authorization': 'Bearer ' + userAuth };
    } else {
        return {};
    }
}

const adminAuthHeader = function() {
    const userAuth = getAdminAuthUser();
    if (userAuth) {
        return { 'Authorization': 'Bearer ' + userAuth };
    } else {
        return {};
    }
}
const removeCookieByName = function(name) {
    cookies.remove(name, null, getDomain(window.location.hostname))
}

export { authHeader, getAuthUser, setAuthUser, resetAuthUser,getCookieByName,setCookieByName,  setCookieByTime,adminAuthHeader,resetAdminAuthUser,setAdminAuthUser,getAdminAuthUser, getDomain, removeCookieByName };