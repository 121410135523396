/*eslint-disable*/
import { authHeader } from "./auth";
import { wrapRequest } from "./nav";

const vueAppMagenotApiUrl = import.meta.env.VITE_APP_MAGENTO_API_URL;
//const vueAppCCUrl = import.meta.env.VITE_APP_CC_URL;
const retailorId = import.meta.env.VITE_APP_RETAILOR_ID;
const vueAppNodeslsApiUrl = import.meta.env.VITE_NODESLS_API_URL;
export const doPostCartItem = wrapRequest(
  (
    sku,
    qty,
    ccid,
    price,
    personalizeDetails,
    builderId,
    quoteId,
    optionId,
    optionValue
  ) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
          product_option: {
            extension_attributes: {
              custom_options: [
                { option_id: optionId, option_value: optionValue },
              ],
            },
          },
        },
        ccid: ccid,
        order_total: price,
        personalize_Data: personalizeDetails,
        builder_id: builderId,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items`,
      requestOptions
    );
  }
);
export const doPostSessionCartItem = wrapRequest(
  ({ sku, qty, price, userQuote, productOption }) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: userQuote,
          sku: sku,
          qty: qty,
          product_option: productOption,
        },
        order_total: price,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items`,
      requestOptions
    );
  }
);
export const doPostSessionWishlistItem = wrapRequest(
  ({ sku, customOptions }) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        wishlistItem: {
          sku: sku,
          custom_options: customOptions,
        },
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items`,
      requestOptions
    );
  }
);
export const doFetchQuote = wrapRequest(() => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };

  return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine`, requestOptions);
});
export const doFetchCartId = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(`${vueAppMagenotApiUrl}/rest/V1/carts/mine`, requestOptions);
});
export const doFetchCartProducts = wrapRequest((userCartId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/cart/data?cartid=${userCartId}`,
    requestOptions
  );
});
export const doFetchTotalAmt = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };

  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/totals`,
    requestOptions
  );
});
export const doDeleteCartProduct = wrapRequest((productId) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${productId}`,
    requestOptions
  );
});
export const doPutMiniCartItem = wrapRequest(
  (sku, qty, quoteId, itemId, ccid, price, personalizeDetails) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
          item_id: itemId,
        },
        ccid: ccid,
        order_total: price,
        personalize_Data: personalizeDetails,
      }),
    };

    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${sku}`,
      requestOptions
    );
  }
);
export const doPutCartItem = wrapRequest(
  (sku, qty, price, quoteId, itemId, personalizeData) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
          item_id: itemId,
        },
        order_total: price,
        personalize_Data: personalizeData,
      }),
    };

    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${sku}`,
      requestOptions
    );
  }
);
export const doPutSessionCartItem = wrapRequest(
  ({ sku, qty, price, userQuote, itemId, productOption }) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: userQuote,
          sku: sku,
          qty: qty,
          item_id: itemId,
          product_option: productOption,
        },
        order_total: price,
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${sku}`,
      requestOptions
    );
  }
);

export const doFetchCoupon = wrapRequest(() => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/coupons`,
    requestOptions
  );
});

export const doApplyDiscountCode = wrapRequest((code) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/coupons/${code}`,
    requestOptions
  );
});
export const doRemoveDiscountCode = wrapRequest(() => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/coupons`,
    requestOptions
  );
});
export const doGetCartItem = wrapRequest((cartId, itemId) => {
  const requestOptions = {
    method: "GEt",
    headers: {
      "Content-type": "application/json",
    },
  };

  return fetch(
    `${
      import.meta.env.VITE_APP_MAGENTO_API_URL
    }/rest/V1/item/data/?cartid=${cartId}&itemId=${itemId}`,
    requestOptions
  );
});
export const doPutCConectionCartItem = wrapRequest((sku, qty, ccid) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-type": "text/xml",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
  };
  return fetch(
    `${vueAppNodeslsApiUrl}/cc-update-cart-item-quantity?retailerID=${retailorId}&orderNumber=${ccid}&itemNumber=${sku}&quantity=${qty}`,
    requestOptions
  );
});
export const doPutGiftCartItem = wrapRequest((data, giftCartDetails) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
      ...authHeader(),
    },
    body: JSON.stringify({
      cartItem: {
        sku: data.sku,
        qty: data.qty,
        quote_id: data.userQuote,
        item_id: data.itemId,
        product_option: {
          extension_attributes: {
            giftcard_item_option: {
              giftcard_sender_name: data.senderName,
              giftcard_sender_email: data.senderEmail,
              giftcard_recipient_name: giftCartDetails.recipientName,
              giftcard_recipient_email: giftCartDetails.recipientEmail,
              giftcard_message: giftCartDetails.senderMessage,
              giftcard_amount: giftCartDetails.giftCardAmt,
            },
          },
        },
      },
    }),
  };
  return fetch(
    `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${data.sku}`,
    requestOptions
  );
});
export const doPutConfiguredCartItem = wrapRequest(
  (sku, qty, quoteId, itemId, optionId, optionValue) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: sku,
          qty: qty,
          item_id: itemId,
          product_option: {
            extension_attributes: {
              configurable_item_options: [
                {
                  option_id: optionId,
                  option_value: optionValue,
                },
              ],
            },
          },
        },
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items/${sku}`,
      requestOptions
    );
  }
);
export const doPostConfiguredCartItem = wrapRequest(
  (itemNumber, quantity, typeId, optionId, optionValue, quoteId) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...authHeader(),
      },
      body: JSON.stringify({
        cartItem: {
          quote_id: quoteId,
          sku: itemNumber,
          qty: quantity,
          product_type: typeId,
          product_option: {
            extension_attributes: {
              configurable_item_options: [
                {
                  option_id: optionId,
                  option_value: optionValue,
                },
              ],
            },
          },
        },
      }),
    };
    return fetch(
      `${vueAppMagenotApiUrl}/rest/V1/carts/mine/items`,
      requestOptions
    );
  }
);
